const leadForm = {
  init: function (formSetting) {
    const { theForm } = formSetting;
    if (!theForm) {
      return false;
    }

    if (leadForm.getParameterByName('sent') == 1) {
      if (localStorage.getItem('contactUs') == 'contactus') {
        document.querySelector('.success-msg').classList.remove('d-none');
        localStorage.removeItem('contactUs');
      } else if (localStorage.getItem('questions') == 'questions') {
        document.querySelector('.success-msg-2').classList.remove('d-none');
        localStorage.removeItem('questions');
      } else {
        document.querySelector('.success-msg').classList.remove('d-none');
        localStorage.removeItem('refund');
      }
    }

    this.validateOnBlur(theForm);

    // listening for click on the submit button
    const submitBtn = theForm.querySelector('button[type=submit]') || theForm.querySelector('input[type=submit]');
    submitBtn.addEventListener(
      'click',
      function (e) {
        e.preventDefault();
        const isFormValid = leadForm.isFormValid(theForm);
        if (!isFormValid) {
          theForm.querySelector('.validation-msg').classList.remove('d-none');
          return false;
        } else {
          leadForm.submit(formSetting);
        }
      },
      true
    );
  },

  getParameterByName: (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  validateOnBlur: function (theForm) {
    if (!theForm) {
      return false;
    }
    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re =
              /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      } else if (type === 'tel') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      } else {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            if (!x || x.trim() == '') {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      }
    }
  },
  isFormValid: function (theForm) {
    if (!theForm) {
      return false;
    }
    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        const x = field.value;
        const re =
          /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      } else if (type === 'tel') {
        const x = field.value;
        const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      } else {
        const x = field.value;
        if (!x || x.trim() == '') {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      }
    }
    return isFormValid;
  },
  submit: function (formSetting) {
    const { theForm } = formSetting;

    const email = theForm.querySelector('input[name="Email"]').value;
    theForm.querySelector('input[name="_fromemail"]').value = email;
    if (theForm.classList.contains('contactus')) {
      const contactUs = 'contactus';
      localStorage.setItem('contactUs', contactUs);
    } else if (theForm.classList.contains('question')) {
      const questions = 'questions';
      localStorage.setItem('questions', questions);
    } else {
      const refund = 'refund';
      localStorage.setItem('refund', refund);
    }

    if (leadForm.getParameterByName('sent') == 1) {
      theForm.querySelector('input[name="_return').value = window.location.href;
    } else {
      theForm.querySelector('input[name="_return"]').value = window.location.href + '?sent=1';
    }

    theForm.action = 'https://home.uceusa.com/mailer.aspx';

    // a simple theForm.submit() doesn't work because we have name=submit in one of the input which override the submit() function on the form
    const submitFormFunction = Object.getPrototypeOf(theForm).submit;
    submitFormFunction.call(theForm);

    // console.log(theForm)
  },
};
